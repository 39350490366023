import Router from './routes/route_before';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <Router></Router>
        </div>
      </header>
    </div>
  );
}

export default App;
