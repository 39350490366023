import { Navigate, useLocation, useRoutes } from "react-router-dom";
import routes from './route_config';

function hasCookie(name)
{
    var arrCookie = document.cookie.split(";")
    for (var i = 0; i < arrCookie.length; i++) {
        var cookiePair = arrCookie[i].split("=")
        if (cookiePair[0] === name) {
            return true
        }
    }
    return false
}

//拦截
const RouterBeforeEach = (props) => {
    const location = useLocation()
    const routerKey = location.pathname

    if (props && props.route && props.route.meta && props.route.meta.title) {
        document.title = props.route.meta.title
    }

    const isLogin = hasCookie('user')
    if (props && props.route && props.route.meta && props.route.meta.needLogin) {
        if (!isLogin) {
            return <Navigate to={'/login'} replace />
        }
    }

    //如果是在登录页面，并且判定为已经登录，则自动跳转到首页
    if (isLogin && ['/login'].includes(routerKey)) {
        return <Navigate to={'/'} replace />
    }

    return (
        <div>
            {props.children}
        </div>
    )
}

//渲染路由
const renderRoutes = (routes) => {
    return routes.map((item) => {
        const route = { meta: item.meta, path: item.path }
        if (item.component) {
            // element 要接收react.element类型 item.component 是对象 所以要转一下
            // route.element = <item.component />
            route.element = <RouterBeforeEach route={item}><item.component /></RouterBeforeEach>
        }

        if (item.children) {
            route.children = renderRoutes(item.children)
        }

        if (item.redirect) {
            route.element = <Navigate to={item.redirect} />
        }
        return route
    })
}

export default function Router() {
    // useRoutes API 把路由数组整合为 <Router> <Route path="xx" element="xxx"></Route>等 </Router>的路由组件  直接用于BrowserRouter中
    return useRoutes(renderRoutes(routes))
}