import { Line } from "@ant-design/plots"
import { DatePicker, Select } from "antd"
import axios from "axios"
import { useEffect, useState } from "react"
import { GameDetailUrl, DailyConfig, HourlyConfig, SelectGroupConfig } from "../common/Config"

function PlayTimeTrend({gameId}) {
  const [showDataView, setShowDataView] = useState(0)
  const [dailyData, setDailyData] = useState([])
  const [hourlyData, setHourlyData] = useState([])
  const [showedHourlyData, setShowHourlyData] = useState([])
  let url = GameDetailUrl + "?id=" + gameId + "&type=playtimetrend"

  useEffect(() => {
    axios.get(url).then((response) => {
        let dailyDataTemp = []
        response.data.daily.forEach((element) => {
            dailyDataTemp.push({
                date: element.date,
                type: 'visits',
                value: element.visits
            })
            dailyDataTemp.push({
                date: element.date,
                type: 'length',
                value: parseFloat((element.length/element.visits/60).toFixed(2))
            })
        })

        let hourlyDataTemp = []
        response.data.hourly.forEach((element) => {
            hourlyDataTemp.push({
                date: element.date,
                hour: element.hour,
                type: 'visits',
                value: element.visits
            })
            hourlyDataTemp.push({
                date: element.date,
                hour: element.hour,
                type: 'length',
                value: parseFloat((element.length/element.visits/60).toFixed(2))
            })
        })

        setDailyData(dailyDataTemp)
        setHourlyData(hourlyDataTemp)
    }).catch((err) => {
      console.log(err)
    })
  }, [url])

  const handleSelectChange = (value) => {
    setShowDataView(value)
  }

  const handlePickDate = (value, dateString) => {
    let pickedDate = dateString.split('-').join('')
    let showedHourlyDataTemp = []
    hourlyData.forEach((element) => {
        if (element.date === pickedDate) {
            showedHourlyDataTemp.push({
                  hour: element.hour,
                  type: element.type,
                  value: element.value,
                })
        }
    })

    // console.log(hourlyData)
    // console.log(showedHourlyDataTemp)
    setShowHourlyData(showedHourlyDataTemp)
  }

  const dailyConfig = {...DailyConfig, data: dailyData}
  const hourlyConfig = {...HourlyConfig, data: showedHourlyData}

  return (
      <>
        <Select
          defaultValue={0}
          onChange={handleSelectChange}
          options={SelectGroupConfig} />
        <>
            {showDataView === 1 ? <DatePicker onChange={handlePickDate} /> : null}
        </>
        <div>
            {showDataView === 0 ? <Line {...dailyConfig} /> : <Line {...hourlyConfig} />}
        </div>
      </>
  )
}

export default PlayTimeTrend