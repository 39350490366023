import { useEffect, useState } from "react"
import { Line } from "@ant-design/plots"
import { Bar } from "@ant-design/plots"
import { DatePicker, Select } from "antd"
import axios from "axios"
import { GameDetailUrl, DailyConfig, HourlyConfig, SelectGroupConfig } from "../common/Config"

const add2DailyArray = (arr, checkDate, checkType, value) => {
  let found = arr.find((e) => {
    return e.date === checkDate && e.type === checkType; 
  })

  if (found) {
    found.value += value
  } else {
    arr.push({
      date: checkDate,
      type: checkType,
      value: value,
    })
  }
}

const add2HourlyArray = (arr, checkDate, checkHour, checkType, value) => {
  let found = arr.find((e) => {
    return e.date === checkDate && e.hour === checkHour && e.type === checkType; 
  })

  if (found) {
    found.value += value
  } else {
    arr.push({
      date: checkDate,
      hour: checkHour,
      type: checkType,
      value: value,
    })
  }
}

function IncomeTrend({incomeData}) {
  const [showDataView, setShowDataView] = useState(0)
  const [dailyData, setDailyData] = useState([])
  const [hourlyData, setHourlyData] = useState({})
  const [showedHourlyData, setShowHourlyData] = useState([])

  useEffect(() => {
    if (!incomeData.daily || !incomeData.hourly || !incomeData.devproduct || !incomeData.gamepass) {
      return
    }

    let dailyInfo = []
    incomeData.daily.forEach(element => {
      if (element.id in incomeData.devproduct) {
        add2DailyArray(dailyInfo, element.date, "Product数量", element.count)
        add2DailyArray(dailyInfo, element.date, "Product销售额", element.cost)
        add2DailyArray(dailyInfo, element.date, "总数量", element.count)
        add2DailyArray(dailyInfo, element.date, "总销售额", element.cost)
      } else if (element.id in incomeData.gamepass) {
        add2DailyArray(dailyInfo, element.date, "GamePass数量", element.count)
        add2DailyArray(dailyInfo, element.date, "GamePass销售额", element.cost)
        add2DailyArray(dailyInfo, element.date, "总数量", element.count)
        add2DailyArray(dailyInfo, element.date, "总销售额", element.cost)
      } else {}
    });
    dailyInfo.sort(function(a, b) {
      return parseInt(a.date) - parseInt(b.date)
    })
    setDailyData(dailyInfo)

    let hourlyInfo = {}
    incomeData.hourly.forEach(element => {
      if (!hourlyInfo[element.date]) {
        hourlyInfo[element.date] = []
      }

      if (element.id in incomeData.devproduct) {
        add2HourlyArray(hourlyInfo[element.date], element.date, element.hour, "Product数量", element.count)
        add2HourlyArray(hourlyInfo[element.date], element.date, element.hour, "Product销售额", element.cost)
        add2HourlyArray(hourlyInfo[element.date], element.date, element.hour, "总数量", element.count)
        add2HourlyArray(hourlyInfo[element.date], element.date, element.hour, "总销售额", element.cost)
      } else if (element.id in incomeData.gamepass) {
        add2HourlyArray(hourlyInfo[element.date], element.date, element.hour, "GamePass数量", element.count)
        add2HourlyArray(hourlyInfo[element.date], element.date, element.hour, "GamePass销售额", element.cost)
        add2HourlyArray(hourlyInfo[element.date], element.date, element.hour, "总数量", element.count)
        add2HourlyArray(hourlyInfo[element.date], element.date, element.hour, "总销售额", element.cost)
      } else {}
    });
    // console.log("hourly data: ", hourlyInfo)
    setHourlyData(hourlyInfo)

  }, [incomeData])

  const handleSelectChange = (value) => {
    setShowDataView(value)
  }
  const handlePickDate= (value, dateString) => {
    let pickedDate = dateString.split('-').join('')
    if (hourlyData[pickedDate]) {
      hourlyData[pickedDate].sort(function(a, b) {
        return parseInt(a.hour) - parseInt(b.hour)
      })
      // console.log("filtered hourly data to show: ", hourlyData[pickedDate])
      setShowHourlyData(hourlyData[pickedDate])
    } else {
      setShowHourlyData([])
    }
  }
  
  const dailyConfig = {...DailyConfig, data: dailyData}
  const hourlyConfig = {...HourlyConfig, data: showedHourlyData}

  return (
    <>
      <h3 style={{ display: "inline" }}>走势</h3>
      <Select
        defaultValue={0}
        onChange={handleSelectChange}
        options={SelectGroupConfig} />
      {showDataView === 1 ? <DatePicker onChange={handlePickDate} /> : null}
      <br/>
      {showDataView === 0 ? <Line {...dailyConfig} /> : <Line {...hourlyConfig} />}
    </>
  )
}

function IncomePortion({incomeData}) {
  const [showDataView, setShowDataView] = useState(0)
  const [dailyQuantity, setDailyQuantity] = useState([])
  const [dailyRevenue, setDailyRevenue] = useState([])
  const [showedDailyQuantity, setShowedDailyQuantity] = useState([])
  const [showedDailyRevenue, setShowedDailyRevenue] = useState([])
  const [totalQuantity, setTotalQuantity] = useState([])
  const [totalRevenue, setTotalRevenue] = useState([])

  const add2PieData = (arr, checkType, value) => {
    let found = arr.find(e => {
      return e.type === checkType
    })

    if (found) {
      found.value += value
    } else {
      arr.push({
        type: checkType,
        value: value
      })
    }
  }

  useEffect(() => {
    if (!incomeData.daily || !incomeData.hourly || !incomeData.devproduct || !incomeData.gamepass) {
      return
    }

    let totalQuantityInfo = []
    let totalRevenueInfo = []
    let dailyQuantityInfo = {}
    let dailyRevenueInfo = {}
    incomeData.daily.forEach(element => {
      let commodityName = incomeData.devproduct[element.id] || incomeData.gamepass[element.id]
      if (commodityName) {
        add2PieData(totalQuantityInfo, commodityName, element.count)
        add2PieData(totalRevenueInfo, commodityName, element.cost)

        if (!dailyQuantityInfo[element.date]) {
          dailyQuantityInfo[element.date] = []
        }
        add2PieData(dailyQuantityInfo[element.date], commodityName, element.count)

        if (!dailyRevenueInfo[element.date]) {
          dailyRevenueInfo[element.date] = []
        }
        add2PieData(dailyRevenueInfo[element.date], commodityName, element.cost)
      }
    })

    setTotalQuantity(totalQuantityInfo)
    setTotalRevenue(totalRevenueInfo)
    setDailyQuantity(dailyQuantityInfo)
    setDailyRevenue(dailyRevenueInfo)
  }, [incomeData])

  const handleSelectChange = (value) => {
    setShowDataView(value)
  }
  const handlePickDate= (value, dateString) => {
    let pickedDate = dateString.split('-').join('')
    if (dailyQuantity[pickedDate]) {
      setShowedDailyQuantity(dailyQuantity[pickedDate])
    } else {
      setShowedDailyQuantity([])
    }

    if (dailyRevenue[pickedDate]) {
      setShowedDailyRevenue(dailyRevenue[pickedDate])
    } else {
      setShowedDailyRevenue([])
    }
  }

  // const pieConfigDailyQuantity = {...PieChartConfig, data: showedDailyQuantity}
  // const pieConfigDailyRevenue = {...PieChartConfig, data: showedDailyRevenue}
  // const pieConfigTotalQuantity = {...PieChartConfig, data: totalQuantity}
  // const pieConfigTotalRevenue = {...PieChartConfig, data: totalRevenue}

  const selectGroupConfig = [
    {key: 0, value: 0, label: "总视图"},
    {key: 1, value: 1, label: "日视图"},
  ]

  const BarConfig = {
    title: {
      visible: true,
      text: '基础条形图',
    },
    description: {
      visible: true,
      text: '基础条形图的图形之间添加转化率标签图形，用户希望关注从上到下的数据变化比例',
    },
    forceFit: true,
    data: totalQuantity,
    xField: 'type',
    yField: 'value',
  }

  const barConfigDailyQuantity = {...BarConfig, data: showedDailyQuantity}
  const barConfigDailyRevenue = {...BarConfig, data: showedDailyRevenue}
  const barConfigTotalQuantity = {...BarConfig, data: totalQuantity}
  const barConfigTotalRevenue = {...BarConfig, data: totalRevenue}

  return (
    <>
      <h3 style={{ display: "inline" }}>组成</h3>
      <Select
        defaultValue={0}
        onChange={handleSelectChange}
        options={selectGroupConfig} />
      {showDataView === 1 ? <DatePicker onChange={handlePickDate} /> : null}
      <br/>
      <h3 style={{ display: "inline" }}>数量占比</h3>
      {showDataView === 1 ? <Bar {...barConfigDailyQuantity} /> : <Bar {...barConfigTotalQuantity} />}
      <h3 style={{ display: "inline" }}>销售额占比</h3>
      {showDataView === 1 ? <Bar {...barConfigDailyRevenue} /> : <Bar {...barConfigTotalRevenue} />}
      {/* {showDataView === 1 ? <Pie {...pieConfigDailyQuantity} /> : <Pie {...pieConfigTotalQuantity} />} */}
      {/* {showDataView === 1 ? <Pie {...pieConfigDailyRevenue} /> : <Pie {...pieConfigTotalRevenue} />} */}
      {/* <Pie {...testPieConfig} /> */}
      <br/>
    </>
  )
}

function Income({gameId}) {
  const [incomeData, setIncomeData] = useState({})
  let url = GameDetailUrl + "?id=" + gameId + "&type=income"

  useEffect(() => {
    axios.get(url).then((response) => {
      // console.log(response.data)
      setIncomeData(response.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [url])

  return (
      <>
        <IncomeTrend incomeData={incomeData}/>
        <IncomePortion incomeData={incomeData}/>
      </>
  )
}

export default Income