import { useEffect, useState } from "react"
import axios from "axios"
import { Column } from "@ant-design/plots"
import { GameDetailUrl } from '../common/Config'

function PlayTime({gameId}) {
  const [totalVisits, setTotalVisits] = useState(0)
  const [dataSource, setDataSource] = useState([])
  let url = GameDetailUrl + "?id=" + gameId + "&type=playtimedist"

  const config = {
    data: dataSource,
    xField: 'interval',
    yField: 'count',
    label: {
      text: (d) => `${(d.count * 100).toFixed(2)}%`,
      textBaseline: 'bottom',
    },
    axis: {
      y: {
        labelFormatter: '.0%',
      },
    },
    style: {
      radiusTopLeft: 10,
      radiusTopRight: 10,
    }
  }

  useEffect(() => {
    axios.get(url).then((response) => {
      // console.log(response.data)

      let total = 0
      let filteredData = []
      let gt30 = {
        interval: "30+",
        count: 0
      }
      response.data.forEach((element) => {
        total += element.count
        if (element.interval > 30) {
          gt30.count += element.count
        } else {
          filteredData.push(element)
        }
      })
      filteredData.push(gt30)

      filteredData.forEach((element) => {
        element.count = element.count / total
      })
      setTotalVisits(total)
      setDataSource(filteredData)
    }).catch((err) => {
      console.log(err)
    })
  }, [url])

  return (
      <>
        <h2>总访问次数：{totalVisits}</h2>
        <Column {...config}/>
      </>
  )
}

export default PlayTime