import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Input, Button, Row, Col, Card, Form } from "antd";
import { LoginUrl } from "../common/Config";

function Login() {
  const [account, setAccount] = useState({
    user: '',
    password: ''
  })

  const navigate = useNavigate()

  const handleInput = (event) => {
    setAccount({...account, [event.target.name]: event.target.value})
  }

  const tryLogin = (event) => {
    event.preventDefault()
    axios.post(LoginUrl, account).then((response) => {
      navigate('/')
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <Row>
      <Col md={{
        span: 8, push:8
      }}>
        <Card title="管理后台"
            style={{maxWidth:600, margin: '40px auto'}}
        >
          <Form
            name="basic"
            style={{maxWidth:600}}
            initialValues={{remember:true}}
            autoComplete="off">
            <Form.Item
              label="用户名"
              name='username'
              rules={[
                {
                  required: true,
                  message: 'Please input your username',
                },
              ]}>
              <Input name="user" type="text" onChange={handleInput} autoComplete="off" placeholder="请输入用户名" />
            </Form.Item>
            <Form.Item
              label="密码"
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your username',
                },
              ]}>
              <Input name="password" type="password" onChange={handleInput} autoComplete="off" placeholder="请输入密码" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset:8,
                span:16,
              }}>
              <Button type="primary" onClick={tryLogin}>登录</Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default Login;