export const DailyConfig = {
  data: [],
  padding: 'auto',
  forceFit: true,
  xField: 'date',
  yField: 'value',
  colorField: 'type',
  axis: {
    y: {
      labelFormatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    },
  },
  scale: { color: { range: ['#30BF78', '#F4664A', '#FAAD14'] } },
  style: {
    lineWidth: 2,
    lineDash: (data) => {
      if (data[0].type === 'register') return [4, 4];
    },
    opacity: (data) => {
      if (data[0].type !== 'register') return 0.5;
    },
  },
};

export const HourlyConfig = {
  data: [],
  padding: 'auto',
  forceFit: true,
  xField: 'hour',
  yField: 'value',
  colorField: 'type',
  axis: {
    y: {
      labelFormatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    },
  },
  scale: { color: { range: ['#30BF78', '#F4664A', '#FAAD14'] } },
  style: {
    lineWidth: 2,
    lineDash: (data) => {
      if (data[0].type === 'register') return [4, 4];
    },
    opacity: (data) => {
      if (data[0].type !== 'register') return 0.5;
    },
  },
};

export const PieChartConfig = {
  data: [],
  colorField: 'type',
  angleField: 'value',
  label: {
    text: (d) => `${d.type}\n ${d.value}`,
    // position: 'spider'
    // style: {
    //   fontWeight: 'bold',
    // },
  },
  legend: {
    color: {
      title: false,
      position: 'right',
      rowPadding: 5,
    },
  },
};


export function Today() {
    const dateObj = new Date()
    let year = dateObj.getFullYear()
    let month = dateObj.getMonth()
    let day = dateObj.getDate()
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = `0${month}`
    }
    return `${year}${month}${day}`
} 

export const DateFormat = "YYYYMMDD"

export const SelectGroupConfig = [
  {key: 0, value: 0, label: "日视图"},
  {key: 1, value: 1, label: "小时视图"},
]

const AntApiUrl = "http://rbx.phonelinksoft.com/antapi"
// const AntApiUrl = "http://127.0.0.1/api"
export const LoginUrl = `${AntApiUrl}/login`
export const GamelistUrl = `${AntApiUrl}/gamelist`
export const ChangeGameInfoUrl = `${AntApiUrl}/game`
export const GameDetailUrl = `${AntApiUrl}/game`


