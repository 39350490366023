import { useSearchParams } from "react-router-dom"
import { useState } from "react"
import { Tabs } from "antd"
import PlayTime from "../pages/PlayTime"
import PlayTimeTrend from "./PlayTimeTrend"
import Income from "../pages/Income"

function GameDetailContent({detailType, gameId}) {
  switch (detailType) {
    case 0:
      return <PlayTimeTrend gameId={gameId} />
    case 1:
      return <PlayTime gameId={gameId} />
    case 2:
      return <Income gameId={gameId} />
    default:
      return null;
  }
}

function Game() {
    const [tab, setTab] = useState(0)
    const [params] = useSearchParams()
    let gameId = params.get('id')
    let gameName = params.get('name')

    const tabItems = [
      {
        label: "时长走势", 
        key: 0, 
        chidren: "内容-0",
      },
      {
        label: "时长分布", 
        key: 1, 
        chidren: "内容-1",
      },
      { 
        label: "收入详情", 
        key: 2, 
        chidren: "内容-2",
      },
    ]

    const onChange = (value) => {
      setTab(value)
    }

    return (
        <>
          <h2>{gameName}</h2>
          <Tabs 
            defaultActiveKey="item-1"
            onChange={onChange}
            items={tabItems}/>
          <GameDetailContent detailType={tab} gameId={gameId} />
        </>
    )
}

export default Game