
import Login from "../pages/Login";
import Home from "../pages/Home";
import Game from "../pages/Game"

const config = [
    {
        path: '/',
        meta: {
            title: '首页',
            needLogin: true,
            isMenu: true
        },
        component: Home
    },
    {
        path: '/home',
        meta: {
            title: '首页',
            needLogin: true,
            isMenu: true
        },
        component: Home
    },
    {
        path: '/login',
        meta: {
            title: '登录',
        },
        component: Login
    },
    {
        path: '/game',
        meta: {
            title: '游戏详情页面',
        },
        component: Game
    },
]

export default config