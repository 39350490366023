import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Select, Table } from "antd";
import { GamelistUrl, ChangeGameInfoUrl } from '../common/Config'

const GroupNameLabel = {
  Default: "未分组",
  Public: "公共",
  UNO: "UNO",
  Barbie: "芭比",
  MoFang: "魔方",
  XianYu: "咸鱼",
}

function Home() {
  const [gameCount, setGameCount] = useState(0)
  const [dataSource, setDataSource] = useState([])
  const [yesVisits, setYesVisits] = useState(0)
  const [yesCost, setYesCost] = useState(0)
  const [arppu, setArppu] = useState(0)

  const groupSelection = [{
    key: 1,
    value: "Default",
    label: GroupNameLabel["Default"],
  }, {
    key: 2,
    value: "Public",
    label: GroupNameLabel["Public"],
  }, {
    key: 3,
    value: "UNO",
    label: GroupNameLabel["UNO"],
  }, {
    key: 4,
    value: "Barbie",
    label: GroupNameLabel["Barbie"],
  }, {
    key: 5,
    value: "MoFang",
    label: GroupNameLabel["MoFang"],
  }, {
    key: 6,
    value: "XianYu",
    label: GroupNameLabel["XianYu"],
  }]

  const visitFilterConfig = [{
    text: "大于10万次",
    value: "100000-99999999",
  },{
    text: "1万次-10万次",
    value: "10000-100000",
  },{
    text: "1千次-1万次",
    value: "1000-10000",
  },{
    text: "小于1千次",
    value: "0-1000",
  }]

  const costFilterConfig = [{
    text: "大于10万",
    value: "100000-99999999",
  },{
    text: "1万-10万",
    value: "10000-100000",
  },{
    text: "1千-1万",
    value: "1000-10000",
  },{
    text: "小于1千",
    value: "0-1000",
  }]

  const groupFilterConfig = [{
    text: GroupNameLabel["Default"],
    value: "Default",
  }, {
    text: GroupNameLabel["Public"],
    value: "Public",
  }, {
    text: GroupNameLabel["UNO"],
    value: "UNO",
  }, {
    text: GroupNameLabel["Barbie"],
    value: "Barbie",
  }, {
    text: GroupNameLabel["MoFang"],
    value: "MoFang",
  }, {
    text: GroupNameLabel["XianYu"],
    value: "XianYu",
  }]

  const handleChange = (newValue, id) => {
    axios.post(ChangeGameInfoUrl, {id, group: newValue}).then((response) => {
    }).catch((err) => {
        console.log(err)
    })
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    setGameCount(extra.currentDataSource.length)

    let visits = 0
    let cost = 0
    extra.currentDataSource.forEach( e => {
      visits += e.visits
      cost += e.cost
    })
    setYesVisits(visits.toLocaleString())
    setYesCost(cost.toLocaleString())
    if (visits > 0) {
      setArppu((cost / visits).toFixed(2))
    }
  }

  const columns = [
    {
      title: '游戏id',
      dataIndex: 'id',
      key: 'id',
      sorter: function(a, b) {
        return a.id.localeCompare(b.id)
      },
      render: (text, category) => (
        <>
          <a href={`https://www.roblox.com/games/${category.id}`}>{text}</a>
        </>
      ),
    },
    {
      title: '游戏名称',
      dataIndex: 'name',
      key: 'name',
      sorter: function(a, b) {
        return a.name.localeCompare(b.name)
      },
      render: (text, category) => (
        <>
          <NavLink to={`/game?id=${category.id}&name=${text}`}>{text}</NavLink>
        </>
      ),
    },
    {
      title: `昨天访问量(共: ${yesVisits} 次)`,
      dataIndex: 'visits',
      key: 'visits',
      filters: visitFilterConfig,
      onFilter: function(value, record) {
        let arr = value.split('-')
        let lower = parseInt(arr[0])
        let upper = parseInt(arr[1])
        return record.visits >= lower && record.visits < upper
      },
      sorter: function(a, b) {
        return a.visits - b.visits
      },
    },
    {
      title: `昨天平均访问时长`,
      dataIndex: 'vlength',
      key: 'vlength',
      sorter: function(a, b) {
        return a.vlength - b.vlength
      },
    },
    {
      title: `昨天销售额(共: ${yesCost} Robux)`,
      dataIndex: 'cost',
      key: 'cost',
      filters: costFilterConfig,
      onFilter: function(value, record) {
        let arr = value.split('-')
        let lower = parseInt(arr[0])
        let upper = parseInt(arr[1])
        return record.cost >= lower && record.cost < upper
      },
      sorter: function(a, b) {
        return a.cost - b.cost
      },
    },
    {
      title: `估算变现率(${arppu})`,
      dataIndex: 'arppu',
      key: 'arppu',
      sorter: function(a, b) {
        return a.arppu - b.arppu
      },
    },
    {
      title: '所属分组',
      dataIndex: 'group',
      key: 'group',
      filters: groupFilterConfig,
      onFilter: function(value, record) {
        return value === record.group
      },
      render: (text, category) => (
        <>
          <Select
            defaultValue={{
              value: category.group,
              label: GroupNameLabel[category.group],
            }}
            onChange={(value) => handleChange(value, category.id)}
            options={groupSelection}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    axios.get(GamelistUrl).then((response) => {
      // console.log(response.data)
      let source = []
      let visits = 0
      let cost = 0
      response.data.forEach((element, idx) => {
        let e = {
          key: idx,
          id: element.gameId,
          name: element.gameName,
          visits: element.yesterdayVisits,
          vlength: 0,
          cost: element.yesterdayCost,
          arppu: 0,
          group: element.group,
        }

        if (element.yesterdayVisits > 0) {
          e.arppu = (element.yesterdayCost / element.yesterdayVisits).toFixed(2)
          e.vlength = (element.yesterdayVisitLength / element.yesterdayVisits / 60).toFixed(2)
        }
        source.push(e)

        visits += element.yesterdayVisits
        cost += element.yesterdayCost
      });

      setYesVisits(visits.toLocaleString())
      setYesCost(cost.toLocaleString())
      setGameCount(source.length)
      setDataSource(source)
      if (visits > 0) {
        setArppu((cost / visits).toFixed(2))
      }

      // const groups = [...new Set(response.data.map(gameItem => gameItem.group))]
      // const groupArr = []
      // groups.forEach((value, idx) => {
      //   groupArr.push({
      //     key: idx,
      //     value: value,
      //     label: value
      //   })
      // })
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  const changeTableTitle = () => {
    return "共" + gameCount + "个游戏"
  }

  return (
    <>
      <div>
        <Table title={changeTableTitle} 
          dataSource={dataSource} 
          columns={columns} 
          bordered 
          pagination={false} 
          onChange={onTableChange}
          style={{margin: '40px 40px 40px 40px'}}
          size="small" />
      </div>
    </>
  )
}

export default Home;